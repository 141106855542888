import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import InsideHeader from '../components/insideheader'
import DemoCarousel from '../components/democarousel'
import ReactMarkdown from 'react-markdown'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Metatags from '../components/Metatags'
export default ({ data }) => {
  const page = data.dataJson
  var rows = [];



  const ImgSlider = ({  url }) =>{
    return(
    <div  className="img-ctn" >
      <img src={url} className="img-responsive"/>
    </div>
  );
  }

  return (
      <Layout>
      <Metatags
               title={page.meta.metatitle}
               keywords={page.meta.metakeywords}
               description={page.meta.metadescription}
               thumbnail={page.meta.metaimage}
               url={page.meta.metaurl}
               pathname={page.meta.metapathname}
           />
          <InsideHeader pageTitle={page.title} pageSubTitle={page.subTitle} pageBackground={page.titleBackground}/>
          <main className="main-content">
              <section className="section">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-10 mx-auto">

                            <Carousel showArrows={true} showThumbs={true}>
                            {
                                page.contents.map((item, index) => (
                                  <div key={index}>
                                     <img src={item.image} />
                                     <p  style={{fontSize: '16px'}} className="legend">{item.heading}</p>
                                  </div>

                                ))
                            }
                            </Carousel>

                          </div>
                      </div>
                  </div>
              </section>
          </main>
      </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
        title
        subTitle
        titleBackground
        contents {
           heading
           image
           content
         }
         meta {
             metadescription
         metakeywords
         metatitle
         metaimage
         metaurl
         metapathname
         }
    }
  }
`
